import React from "react";

import PropTypes from "prop-types";

const TitleBanner = ({ text, subText, background, textColor }) => {
  return (
    <div className={`text-center pt-4 pb-4 bg-${background}`}>
      <h1 className={`text-${textColor}`}>{text}</h1>

      {subText && <h4 className={`text-${textColor}`}>{subText}</h4>}
    </div>
  );
};

TitleBanner.propTypes = {
  text: PropTypes.string.isRequired,
  subText: PropTypes.string,
  background: PropTypes.string,
  textColor: PropTypes.string,
};

TitleBanner.defaultProps = {
  background: "primary",
  textColor: "light",
};

export default TitleBanner;
