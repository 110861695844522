import React from "react";

import { Container, Jumbotron, Image, Row, Col } from "react-bootstrap";

import Title from "./TitleBanner";

import img from "../assets/images/about-img.jpg";

const AboutSection = () => {
  return (
    <div className="mt-4">
      <Title text="Why Choose Us?" background="secondary" />
      <Jumbotron style={{ backgroundColor: "white" }}>
        <Container fluid="xl">
          <Row>
            <Col lg={7} className="mb-4">
              <h1 className="mb-4">Lorem Ipsum Dolor</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                veniam iste eius accusantium est voluptate nesciunt nostrum vero
                dicta, voluptatem ad nisi dolores adipisci eum quaerat velit
                vel, eos ipsa.
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                veniam iste eius accusantium est voluptate nesciunt nostrum vero
                dicta, voluptatem ad nisi dolores adipisci eum quaerat velit
                vel, eos ipsa.
              </p>
            </Col>

            <Col lg={5}>
              <Image rounded fluid src={img} />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default AboutSection;
