import React from "react";

import background from "../assets/images/main-background.jpg";

const LandingBanner = () => {
  const style = {
    background: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
    padding: "18rem 0",
  };

  const headerStyle = {
    fontSize: "clamp(1.5rem, 10vw, 4rem)",
    width: "95%",
    maxWidth: "500px",
    margin: "auto",
    padding: "2rem",
    textTransform: "uppercase",
  };
  return (
    <>
      <div style={style} className="bg-dark">
        <h1
          className="text-light text-center bg-overlay-secondary rounded"
          style={headerStyle}
        >
          IP Services
        </h1>
      </div>
    </>
  );
};

export default LandingBanner;
