import React from "react";
import "./sass/App.scss";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import { BrowserRouter as Router } from "react-router-dom";

import Pages from "./routes/Routes";

const App = () => (
  <Router>
    <Header />

    <Pages />

    <Footer />
  </Router>
);

export default App;
