import React from "react";

import { LinkContainer } from "react-router-bootstrap";

import logo from "../../assets/logos/lan.svg";

import { Navbar, Nav, Image } from "react-bootstrap";

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <LinkContainer to="/">
        <Navbar.Brand>
          <Image src={logo} alt="Logo" width="100" />
        </Navbar.Brand>
      </LinkContainer>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <LinkContainer to="/">
            <Nav.Link href="#home">Home</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/">
            <Nav.Link href="#features">About</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/">
            <Nav.Link href="#pricing">Services</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/">
            <Nav.Link href="#pricing">Contact</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
