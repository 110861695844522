import React from "react";

import Title from "./TitleBanner";

import { Table, Container } from "react-bootstrap";

const PackagesSection = () => {
  return (
    <>
      <Title text="Packages" />
      <Container className="mt-5" fluid="xl">
        <Table bordered hover responsive striped variant="dark">
          <thead className="text-primary">
            <tr>
              <th></th>
              <th>
                Cloud Internet 10
                <br />
                <small>
                  <ul>
                    <li>300GB Usage</li>
                    <li>Download up to 10 MB/s</li>
                    <li>Upload up to 1 MB/s</li>
                  </ul>
                </small>
              </th>
              <th>
                Cloud Internet 15+
                <br />
                <small>
                  <ul>
                    <li>300GB Usage</li>
                    <li>Download up to 15 MB/s</li>
                    <li>Upload up to 10 MB/s</li>
                  </ul>
                </small>
              </th>
              <th>
                Cloud Internet 25+
                <br />
                <small>
                  <ul>
                    <li>300GB Usage</li>
                    <li>Download up to 25 MB/s</li>
                    <li>Upload up to 10 MB/s</li>
                  </ul>
                </small>
              </th>
              <th>
                Cloud Internet 50
                <br />
                <small>
                  <ul>
                    <li>300GB Usage</li>
                    <li>Download up to 50 MB/s</li>
                    <li>Upload up to 10 MB/s</li>
                  </ul>
                </small>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th className="text-primary">
                Home Phone
                <br />
                <small>Local On-Net*</small>
              </th>
              <td className="text-center">$49.99/month</td>
              <td className="text-center">$51.99/month</td>
              <td className="text-center">$56.99/month</td>
              <td className="text-center">$71.99/month</td>
            </tr>

            <tr>
              <th className="text-primary">
                Home Phone
                <br />
                <small>Canada Wide**</small>
              </th>
              <td className="text-center">$54.99/month</td>
              <td className="text-center">$56.99/month</td>
              <td className="text-center">$61.99/month</td>
              <td className="text-center">$76.99/month</td>
            </tr>

            <tr>
              <th className="text-primary">
                Home Phone
                <br />
                <small>North America**</small>
              </th>
              <td className="text-center">$64.99/month</td>
              <td className="text-center">$66.99/month</td>
              <td className="text-center">$71.99/month</td>
              <td className="text-center">$86.99/month</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

PackagesSection.propTypes = {};

export default PackagesSection;
