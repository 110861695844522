import React from "react";

import LandingBanner from "../LandingBanner";
import ServicesSection from "../ServicesSection";
import PackagesSection from "../PackagesSection";
import AboutSection from "../AboutSection";
import ContactSection from "../ContactSection";

const Home = () => {
  return (
    <>
      <LandingBanner />
      <ServicesSection />
      <PackagesSection />
      <AboutSection />
      <ContactSection />
    </>
  );
};

export default Home;
