import React from "react";

import Title from "./TitleBanner";

import { Card, Row, Col, Button, Container } from "react-bootstrap";

import {
  BsFillHouseDoorFill,
  BsBuilding,
  BsFillBucketFill,
} from "react-icons/bs";

const ServicesSection = () => {
  return (
    <>
      <Title text="Services" background="secondary" />

      <Container fluid className="mt-5 mb-5">
        <Row>
          <Col md className="mb-4">
            <Card className="h-100 border-primary">
              <Card.Body>
                <Card.Title className="text-primary text-center">
                  Residential
                </Card.Title>
                <Card.Text>
                  <BsFillHouseDoorFill className="card-icon" />
                  <br />
                  Now Available Home Phone, Internet, Alarm system and Security
                  cameras.
                </Card.Text>
                <Button
                  variant="secondary"
                  size="lg"
                  className="d-block mx-auto"
                >
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md className="mb-4">
            <Card className="h-100 border-primary">
              <Card.Body>
                <Card.Title className="text-primary text-center">
                  Business
                </Card.Title>
                <Card.Text>
                  <BsBuilding className="card-icon" />
                  <br />
                  Now Available Business Phone, Internet, Security system, Cloud
                  Backup, Server Colocation and more.
                </Card.Text>
                <Button
                  variant="secondary"
                  size="lg"
                  className="d-block mx-auto"
                >
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md className="mb-4">
            <Card className="h-100 border-primary">
              <Card.Body>
                <Card.Title className="text-primary text-center">
                  Wholesale
                </Card.Title>
                <Card.Text>
                  <BsFillBucketFill className="card-icon" />
                  <br />
                  We offer Canadian Sip Trunking, Local and Toll Free DID
                  origination and more.
                </Card.Text>
                <Button
                  variant="secondary"
                  size="lg"
                  className="d-block mx-auto"
                >
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ServicesSection;
