import React from "react";

import Title from "./TitleBanner";

import { Container, Form, Row, Col, Button } from "react-bootstrap";

import { BsFillEnvelopeFill, BsPhone, BsMap } from "react-icons/bs";

const ContactSection = () => {
  return (
    <>
      <Title text="Contact Us" />

      <Container className="mt-5 mb-5">
        <Row>
          <Col lg>
            <Row className="mt-4 mb-5">
              <Col lg={1} className="h2">
                <BsPhone />
              </Col>
              <Col lg className="h4">
                <a href="tel: 905 123-1111">(905) 123-1111</a>
              </Col>
            </Row>

            <Row className="mb-5">
              <Col lg={1} className="h2">
                <BsFillEnvelopeFill />
              </Col>
              <Col lg className="h4">
                <a href="mailto:info@linktek.com">info@ip-phones.com</a>
              </Col>
            </Row>

            <Row className="mb-5">
              <Col lg={1} className="h2">
                <BsMap />
              </Col>
              <Col lg className="h4">
                <a href="#!">
                  100 Example St
                  <br />
                  Hamilton, Ontario, L1A 2B3
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg>
            <Form>
              <Form.Group controlId="name">
                <Form.Label className="text-secondary">Full Name</Form.Label>
                <Form.Control type="text" placeholder="Your Full Name..." />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label className="text-secondary">Email</Form.Label>
                <Form.Control type="email" placeholder="Your Email..." />
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label className="text-secondary">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Your Message..."
                />
              </Form.Group>

              <Button variant="secondary" type="submit">
                Send Message
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactSection;
