import React from "react";

import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="bg-primary text-light">
      <Container fluid className="p-5">
        <h3 className="mb-4">Copyright &copy; IP Phones 2020</h3>

        <h5>Designed By Zing Media</h5>
      </Container>
    </div>
  );
};

export default Footer;
